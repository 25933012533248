import React from "react";
import styled from "styled-components";
import Col from "../components/Col";
import Layout from "../components/Layout";
import Row from "../components/Row";
import Container from "../components/Container";
import Section from "../components/Section";
import Seo from "../components/Seo";
import Title from "../components/Title";
import Button from "../components/Button";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Page Not Found" />
      <Section>
        <Container>
          <Row justify="center">
            <Col lg={8}>
              <Center>
                <Title size={1} mb={4}>
                  Page Not Found
                </Title>
                <p>
                  We are having trouble finding the page you are looking for.
                  <br/>
                  You might have mistyped or the page no longer exists.</p>
                <br />
                <br />
                <Button to="/">Return to homepage</Button>
              </Center>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

const Center = styled.div`
  text-align: center;
`;

export default PrivacyPolicy;
